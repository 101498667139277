import React from 'react'
import clsx from 'clsx'
import styles from './styles'
import { makeStyles } from '@material-ui/core/styles'
import Header from 'components/Header'

const useStyles = makeStyles(styles)

interface IProps {
  classes?: {
    root: string
  }
  appbarColor?: 'light' | 'dark'
  searchRefinement?: string
}

const BasicLayout: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.root, props.classes?.root && props.classes.root)}
    >
      <Header
        theme={props.appbarColor || 'dark'}
        searchRefinement={props.searchRefinement}
        bgColor={props.appbarColor === 'light' ? 'default' : 'primary'}
      />

      <div className="main">
        <div className="main" style={{ minHeight: 'calc(100vh - 100px)' }}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default BasicLayout
