import { Grid } from '@material-ui/core'
import CardVideo from 'components/CardVideo'
import Pagination from 'components/Pagination'
import { useStudent } from 'contexts/StudentContext'
import { usePagination } from 'hooks'
import React from 'react'
import { VideoType } from 'types/models'

interface IProps {}

const LIMIT = 16

const StudyHistoriesTabCompleted: React.FC<IProps> = () => {
  const student = useStudent()

  const studying = usePagination<VideoType>(
    student._meta.ref
      .collection('histories')
      .where('progress', '==', 100)
      .orderBy('ordering', 'desc'),
    LIMIT
  )

  return (
    <>
      <Grid spacing={2} container>
        {studying.items.map((video) => {
          return (
            <Grid key={video.id} item xs={12} sm={6} md={3}>
              <CardVideo video={video}></CardVideo>
            </Grid>
          )
        })}

        {studying.currentPage === 0 && studying.items.length < LIMIT ? null : (
          <Pagination pagination={studying}></Pagination>
        )}
      </Grid>
    </>
  )
}

export default StudyHistoriesTabCompleted
