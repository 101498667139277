import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from './styles'

const useStyle = makeStyles(styles)

interface IProps {}

const Loading: React.FC<IProps> = (props) => {
  const classes = useStyle()
  return <div className={classes.loadingCtn}>
    <CircularProgress />
  </div>
}

export default Loading