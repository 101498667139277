import React, { useCallback, useEffect } from 'react'
import { useFirestore, useUser } from 'reactfire'
import Loading from 'components/Loading'
import { useHistory } from 'react-router-dom'
import { useDocumentData } from 'hooks'
import { Student } from 'types/models'
import StudentContext from 'contexts/StudentContext'
import { firestore } from 'firebase'

interface IProps {}

const HasDoneSurvey: React.FC<IProps> = (props) => {
  const db = useFirestore()
  const user = useUser<firebase.User>()

  const history = useHistory()

  const student = useDocumentData<Student>(
    db.collection('/students').doc(user.uid || ''),
    { idField: 'id' }
  )

  const goToSurvey = useCallback(() => {
    if (!student || !student.hasDoneSurvey) {
      localStorage.setItem('old_direction', window.location.pathname)
      history.push('/survey')
    }
  }, [history, student])

  useEffect(() => {
    if (!student) {
      db.collection('/students')
        .doc(user.uid)
        .set({
          timeTracks: {
            createdAt: firestore.FieldValue.serverTimestamp(),
            updatedAt: firestore.FieldValue.serverTimestamp()
          }
        })
        .then(() => goToSurvey())
    } else {
      goToSurvey()
    }
  }, [db, goToSurvey, student, user.uid])

  return (
    <>
      <StudentContext.Provider value={student}>
        {props.children}
      </StudentContext.Provider>
    </>
  )
}

const Authentication: React.FC<IProps> = (props) => {
  const user = useUser<firebase.User>()
  const history = useHistory()

  useEffect(() => {
    if (!user) {
      localStorage.setItem('old_direction', window.location.pathname)
      history.push('/login')
    }
  }, [user, history])

  if (!user) {
    return <Loading />
  }

  return (
    <>
      <HasDoneSurvey> {props.children} </HasDoneSurvey>
    </>
  )
}

export default Authentication
