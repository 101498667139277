import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Tabs as MaterialTabs } from '@material-ui/core'
import React from 'react'

interface IProps {
  value: number
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
}

const Tabs = withStyles((theme: Theme) => {
  createStyles({
    root: {},
    indicator: {
      backgroundColor: theme.palette.background.default
    }
  })
})((props: IProps) => <MaterialTabs {...props} />)

export default Tabs
