import * as rf from 'reactfire'
import { IFirestoreMetadata } from 'interfaces'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'

export function metaRef(
  input: object,
  ref: firebase.firestore.DocumentReference
): IFirestoreMetadata {
  return { ...input, _meta: { ref: ref } }
}

const CACHED_QUERY: firebase.firestore.Query[] = []

const getIndexQueryCached = (query: firebase.firestore.Query): number => {
  const index = _.findIndex(CACHED_QUERY, (cachedQuery) =>
    cachedQuery.isEqual(query)
  )

  if (index > -1) {
    return index
  }

  return CACHED_QUERY.push(query) - 1
}

/**
 * listen to a document
 *
 * @param ref
 * @param options
 */
export function useDocument<T>(
  ref: firebase.firestore.DocumentReference,
  options: rf.ReactFireOptions<T>
) {
  return rf.useFirestoreDoc<T>(ref, options)
}

/**
 * listen to a document
 * return document data attached _meta.ref (DocumentReference)
 * @param ref
 * @param options
 */
export function useDocumentData<T extends object>(
  ref: firebase.firestore.DocumentReference,
  options?: rf.ReactFireOptions<T>
): (T & IFirestoreMetadata) | undefined {
  const { idField } = options ? options : { idField: undefined }

  const data = rf.useFirestoreDocData<T>(ref, options)

  return useMemo(() => {
    if (_.isEmpty(idField ? _.omit(data, idField) : data)) {
      return undefined
    }
    return metaRef(data, ref) as T & IFirestoreMetadata
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, idField])
}

export function useDocumentDataOne<T extends object>(
  ref: firebase.firestore.DocumentReference,
  options?: rf.ReactFireOptions<T>
) {
  const { idField } = options ? options : { idField: undefined }

  const data = rf.useFirestoreDocDataOnce<T>(ref, options)

  return useMemo(() => {
    if (_.isEmpty(idField ? _.omit(data, idField) : data)) {
      return undefined
    }
    return metaRef(data, ref) as T & IFirestoreMetadata
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, idField])
}

/**
 * listen to a collection's document filtered by query
 * @param query
 * @param options
 */
export function useCollection<T>(
  query: firebase.firestore.Query,
  options: rf.ReactFireOptions<Array<T>>
) {
  return rf.useFirestoreCollection<T>(query, options)
}

/**
 * listen to a collection's documents filtered by query
 * return option attached _meta.ref: (DocumentReference)
 * @param query
 * @param options
 */
export function useCollectionData<T extends object>(
  query: firebase.firestore.Query,
  options?: rf.ReactFireOptions<T[]>
): Array<T & IFirestoreMetadata> {
  const snapshot = rf.useFirestoreCollection(
    query,
    options
  ) as firebase.firestore.QuerySnapshot
  return useMemo(() => {
    const docs = snapshot.docs
    return docs.map((doc) => {
      return metaRef(
        {
          ...doc.data(),
          ...(options?.idField ? { [options.idField]: doc.id } : null)
        },
        doc.ref
      ) as T & IFirestoreMetadata
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshot])
}

export const useAssocCollectionData = <T extends IFirestoreMetadata>(
  query: firebase.firestore.Query,
  assocField: string,
  options?: rf.ReactFireOptions<T[]>
) => {
  const idField = options?.idField || assocField
  const snapshots = rf.useFirestoreCollection(
    query,
    options
  ) as firebase.firestore.QuerySnapshot

  const [dataAssoc, setDataAssoc] = useState<{ [key: string]: T }>({})

  useEffect(() => {
    const data: { [key: string]: T } = {}

    snapshots.forEach((snapshot) => {
      const doc = { [idField]: snapshot.id, ...snapshot.data() } as {
        [key: string]: any
      }

      if (!doc[assocField]) {
        throw new Error(
          'Something when wrong, document does not have associate field ' +
            assocField +
            ' or field value was empty'
        )
      }

      data[doc[assocField]] = metaRef(doc, snapshot.ref) as T &
        IFirestoreMetadata
    })
    setDataAssoc(data)
  }, [assocField, idField, snapshots])

  return dataAssoc
}

export const useCollectionDataOnce = <T extends object>(
  query: firebase.firestore.Query,
  options?: rf.ReactFireOptions<T[]>
) => {
  const [state, setState] = useState<T[]>([])

  const cachedQueryIndex = getIndexQueryCached(query)

  useEffect(() => {
    query.get().then((querySnapshot) => {
      const data: T[] = []
      querySnapshot.forEach((doc) => {
        data.push(
          metaRef(
            {
              ...doc.data(),
              ...(options?.idField ? { [options.idField]: doc.id } : null)
            },
            doc.ref
          ) as T & IFirestoreMetadata
        )
      })

      setState(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedQueryIndex])

  return state
}
