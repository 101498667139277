import { createStyles, Theme } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    logo: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2),
      '& > img': {
        height: '30px'
      },
      [theme.breakpoints.up('md')]: {
        '& > img': {
          height: '60px'
        }
      },
      [theme.breakpoints.up('sm')]: {
        '& > img': {
          height: '50px'
        }
      }
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2),
      '& > img': {
        height: '30px'
      },
      [theme.breakpoints.up('md')]: {
        '& > img': {
          height: '60px'
        }
      },
      [theme.breakpoints.up('sm')]: {
        '& > img': {
          height: '50px'
        }
      }
    }
  })
