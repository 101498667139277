import config from 'configs'

export const combineTitle = (sep: string, ...args: string[]): string => {
  const sanitized: string[] = []
  for (let i = 0; i < args.length; ++i) {
    if (args[i]) {
      sanitized.push(args[i])
    }
  }
  return sanitized.join(sep)
}

export const getPageTitle = (title?: string): string => {
  if (!title) {
    return combineTitle(' - ', config.app.siteName, config.app.siteSlogan)
  }
  return combineTitle(' - ', title, config.app.siteName)
}

export const getOldDirection = () => {
  let oldDirection = localStorage.getItem('old_direction')
  if (!oldDirection) {
    oldDirection = '/'
  } else {
    localStorage.removeItem('old_direction')
  }

  return oldDirection
}

export const numberFormat = (number: number) => {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d{1}))/g, '$1,')
}

export const getLocation = async () => {
  try {
    const position = await new Promise<Position>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject)
    })

    return {
      longitude: position.coords.longitude,
      latitude: position.coords.latitude
    }
  } catch (e) {
    return {
      longitude: -1,
      latitude: -1
    }
  }
}
