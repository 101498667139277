import { IFirestoreMetadata } from 'interfaces'
import React, { useContext } from 'react'
import { Student } from 'types/models'

const StudentContext = React.createContext<
  (Student & IFirestoreMetadata) | undefined
>(undefined)

export const useStudent = () => {
  const student = useContext(StudentContext)
  if (!student) {
    throw new Error("Something's went wrongs! user not authenticate")
  }

  return student
}

export default StudentContext
