import {
  preloadFirestore,
  preloadAuth,
  preloadStorage,
  useAnalytics,
  preloadFunctions
} from 'reactfire'
import { useEffect, FC } from 'react'
import firebase from 'firebase'
import { history } from 'providers/router/BrowserHistory'

export const PageViewLogger: FC = () => {
  const analytics = useAnalytics()
  const { location } = history

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      analytics.logEvent('page-view', { path_name: location.pathname })
    }
  }, [location.pathname, analytics])

  return null
}

export const preloadSDKs = (firebaseApp: firebase.app.App) => {
  return Promise.all([
    preloadFirestore({
      firebaseApp: firebaseApp,
      setup: (firestore) =>
        firestore().enablePersistence({ synchronizeTabs: true })
    }),
    preloadAuth({
      firebaseApp: firebaseApp
    }),
    preloadStorage({
      firebaseApp: firebaseApp,
      setup: (storage) => storage().setMaxUploadRetryTime(10000)
    }),
    preloadFunctions({
      firebaseApp: firebaseApp,
      setup: (functions) =>
        functions().useFunctionsEmulator('http://localhost:5001')
    })
  ])
}
