import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link, LinkProps } from 'react-router-dom'
import {
  Button,
  ButtonGroupProps,
  ButtonTypeMap,
  ExtendButtonBase,
  withStyles
} from '@material-ui/core'
import items from './items'
import styles from './styles'

const useStyles = makeStyles(styles)

interface IProps {
  variant?: 'default' | 'primary'
}

interface IRouterLinkProps {
  to: string
  title: string
  keys?: any
  variant?: 'default' | 'primary'
}

const LinkButton: ExtendButtonBase<ButtonTypeMap<
  ButtonGroupProps & LinkProps,
  'button'
>> = (props: any) => {
  return <Button component={Link} {...props} />
}

const RouterLink: React.FC<IRouterLinkProps> = (props) => {
  const { title, to } = props

  const MenuButton = withStyles(() => ({
    textPrimary: {
      color: 'white'
    },
    default: {
      color: 'black'
    }
  }))(LinkButton)

  return (
    <MenuButton
      className="menu-item"
      color={props.variant || 'primary'}
      to={to}
    >
      {title}
    </MenuButton>
  )
}

const TopMenu: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.headerMenu}>
      <div className="menu-list">
        {items.map((item, key) => (
          <RouterLink {...props} to={item.link} title={item.title} key={key} />
        ))}
      </div>
    </div>
  )
}

export default TopMenu
