import React, { useState } from 'react'
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  Highlight,
  Configure
} from 'react-instantsearch-dom'
import {
  Box,
  InputAdornment,
  List,
  ListItem,
  Paper,
  TextField,
  ClickAwayListener
} from '@material-ui/core'
import styles from './styles'
import { makeStyles } from '@material-ui/core/styles'
import { Search } from '@material-ui/icons'
import { SearchBoxExposed, SearchBoxProvided } from 'react-instantsearch-core'
import { useHistory } from 'react-router-dom'
import { appId, apiKey } from 'configs/algolia-config.json'
import clsx from 'clsx'

const useStyles = makeStyles(styles)

const searchClient = algoliasearch(appId, apiKey)

interface IProps {
  searchRefinement?: string
  theme: 'light' | 'dark'
}

interface SuggestionDoc {
  content: string
}

const SearchBar: React.FC<IProps> = (props) => {
  const { searchRefinement } = props
  const classes = useStyles()
  const history = useHistory()
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)

  const SearchBox: React.FC<SearchBoxProvided> = ({
    currentRefinement,
    refine
  }) => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault()
          history.push(`/results/?search_query=${currentRefinement}`)
          setShowSuggestions(false)
        }}
      >
        <TextField
          color="primary"
          defaultValue={searchRefinement || ''}
          className={clsx({
            [classes.textField]: true,
            [classes.primaryTheme]: props.theme === 'dark'
          })}
          onFocus={() => {
            setShowSuggestions(true)
          }}
          variant={'outlined'}
          size={'small'}
          fullWidth
          placeholder="Tìm kiếm..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search className={classes.searchIcon} />
              </InputAdornment>
            )
          }}
          onChange={(event) => {
            refine(event.target.value)
          }}
        />
      </form>
    )
  }

  const ConnectSearchBox: React.ComponentClass<SearchBoxExposed> = connectSearchBox(
    SearchBox
  )

  const ConnectSearchSuggestions: React.ComponentClass<{}, any> = connectHits<
    SuggestionDoc
  >(({ children, hits }) => {
    return (
      <>
        <List>
          {hits.length > 0 ? (
            hits.map((hit) => {
              return (
                <ListItem
                  className={classes.item}
                  button
                  key={hit.objectID}
                  onClick={() => {
                    history.push(`/results/?search_query=${hit.content}`)
                    setShowSuggestions(false)
                  }}
                >
                  <Highlight
                    hit={hit}
                    attribute={'content'}
                    className={classes.hitContent}
                  />
                </ListItem>
              )
            })
          ) : (
            <Box textAlign={'center'}>
              <i className={classes.notFoundText}>Không tìm thấy kết quả nào</i>
            </Box>
          )}
        </List>
      </>
    )
  })

  return (
    <Box>
      <InstantSearch indexName="suggestions" searchClient={searchClient}>
        <Configure hitsPerPage={10} />
        <ClickAwayListener onClickAway={() => setShowSuggestions(false)}>
          <Box>
            <Box className={classes.searchBoxContainer}>
              <Box className={classes.searchBox}>
                <ConnectSearchBox />
              </Box>
            </Box>
            {showSuggestions && (
              <Box className={classes.suggestions} component={Paper}>
                <ConnectSearchSuggestions />
              </Box>
            )}
          </Box>
        </ClickAwayListener>
      </InstantSearch>
    </Box>
  )
}

export default SearchBar
