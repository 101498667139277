import { createMuiTheme } from '@material-ui/core'

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme extends ThemeOptions {}

  interface ThemeOptions {}
}

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteColor {
    50: string
    100: string
    200: string
    300: string
    400: string
    500: string
    600: string
    700: string
    800: string
    900: string
  }
}

export default createMuiTheme({
  typography: {
    fontSize: 14,
    fontFamily: 'Open Sans,Roboto, Helvetica, Arial, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h6: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 600
    },
    h3: {
      fontWeight: 400,
      fontSize: '36px',
      lineHeight: '56px'
    }
  },
  palette: {
    primary: {
      50: '#F2E7FE',
      100: '#DBB2FF',
      200: '#BB86FC',
      300: '#985EFF',
      400: '#7F39FB',
      500: '#3366FF',
      600: '#274BDB',
      700: '#1A34B8',
      800: '#102694',
      900: '#091C7A'
    },
    secondary: {
      main: '#FCBF1E',
      50: '#FFF9EA',
      100: '#FEEDC0',
      200: '#FEE196',
      300: '#FDD56C',
      400: '#FCC942',
      500: '#FCBF1E',
      600: '#E7A803',
      700: '#CE9603',
      800: '#A07502',
      900: '#725302',
      contrastText: 'white'
    },
    success: {
      light: '#5ADB50',
      dark: '#49AF41',
      main: '#4BB543'
    },
    error: {
      main: '#B00020',
      light: '#FF3D71'
    }
  }
})
