import { lazy } from 'react'

export const Dashboard = lazy(() => import('./Dashboard'))
export const Login = lazy(() => import('./Login'))
export const Survey = lazy(() => import('./Survey'))
export const Watch = lazy(() => import('./Watch'))
export const Support = lazy(() => import('./Support'))
export const StudyHistories = lazy(() => import('./StudyHistories'))
export const SearchResults = lazy(() => import('./SearchResults'))
export const MyRocketScreen = lazy(() => import('./MyRocket'))
