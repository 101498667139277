import React from 'react'
import {
  Avatar,
  Button,
  Popper,
  MenuItem,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  makeStyles
} from '@material-ui/core'
import { useUser, useAuth } from 'reactfire'
import { useHistory } from 'react-router-dom'
import styles from './styles'

const useStyles = makeStyles(styles)

interface IProps {}

const UserMenu: React.FC<IProps> = (props) => {
  const [open, setOpen] = React.useState(false)
  const user = useUser<firebase.User>()
  const auth = useAuth()
  const history = useHistory()
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const classes = useStyles()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        history.push('/login')
      })
      .catch((err) => console.error(err))
  }

  const navigateToSupport = () => {
    history.push('/support')
  }

  const navigateToStudyHistory = () => {
    history.push('/histories')
  }
  const navigateToMyRocket = () => {
    history.push('/my-rocket')
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  if (!user) {
    throw new Error("Something's went wrongs!, unauthorized")
  }
  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Avatar src={user.photoURL as string} />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className={classes.menuList}
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={navigateToMyRocket}>Tên lửa</MenuItem>
                  <MenuItem onClick={navigateToStudyHistory}>
                    Lịch sử học tập
                  </MenuItem>
                  <MenuItem onClick={navigateToSupport}>Hỗ trợ</MenuItem>
                  <MenuItem onClick={handleLogout}>Đăng xuất</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default UserMenu
