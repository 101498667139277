import { Box, IconButton } from '@material-ui/core'
import React from 'react'
import { NavigateNext, NavigateBefore } from '@material-ui/icons'
import { PaginationDataType } from 'types'

interface IProps {
  pagination: PaginationDataType<any>
}

const Pagination: React.FC<IProps> = (props) => {
  const { pagination } = props

  return (
    <>
      <Box width="100%" display="flex" justifyContent="flex-end">
        <IconButton
          onClick={pagination.previous}
          disabled={!pagination.hasPrevious}
        >
          <NavigateBefore />
        </IconButton>
        <IconButton onClick={pagination.next} disabled={!pagination.hasNext}>
          <NavigateNext />
        </IconButton>
      </Box>
    </>
  )
}

export default Pagination
