import {
  Dashboard,
  Watch,
  Survey,
  Support,
  SearchResults,
  MyRocketScreen
} from './views'
import { RouteType } from 'types'
import StudyHistories from 'views/StudyHistories'

const routing: Array<RouteType> = [
  { path: '/', component: Dashboard },
  { path: '/survey', component: Survey },
  { path: '/watch/:video_id', component: Watch },
  { path: '/support', component: Support },
  { path: '/histories', component: StudyHistories },
  { path: '/results', component: SearchResults },
  { path: '/my-rocket', component: MyRocketScreen }
]

export default routing
