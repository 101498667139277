import { Grid } from '@material-ui/core'
import CardVideo from 'components/CardVideo'
import { usePagination } from 'hooks'
import React from 'react'
import { useFirestore, useUser } from 'reactfire'
import { VideoType } from 'types/models'
import Pagination from 'components/Pagination'

interface IProps {}

const LIMIT = 16

const VideoFavorites: React.FC<IProps> = () => {
  const user = useUser<firebase.User>()
  const db = useFirestore()

  const videoFavorites = usePagination<VideoType>(
    db
      .collection('students')
      .doc(user.uid)
      .collection('favorites')
      .orderBy('tracking.favoriteAt', 'desc'),
    LIMIT
  )

  return (
    <>
      <Grid spacing={2} container>
        {videoFavorites.items.map((video) => {
          return (
            <Grid key={video.id} item xs={12} sm={6} md={3}>
              <CardVideo video={video}></CardVideo>
            </Grid>
          )
        })}

        {videoFavorites.currentPage === 0 &&
        videoFavorites.items.length < LIMIT ? null : (
          <Pagination pagination={videoFavorites}></Pagination>
        )}
      </Grid>
    </>
  )
}

export default VideoFavorites
