import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Tab as MaterialTab, TabProps } from '@material-ui/core'
import React from 'react'

interface CustomTabProps extends TabProps {}

const Tab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover': {
        color: theme.palette.secondary.dark,
        opacity: 1
      },
      '&$selected': {
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium
      },
      '&:focus': {
        color: theme.palette.secondary.light
      }
    },
    selected: {}
  })
)((props: CustomTabProps) => <MaterialTab {...props} />)

export default Tab
