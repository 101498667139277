import { Theme, createStyles } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    watchedIcon: {
      color: theme.palette.success.light,
      marginRight: theme.spacing(1)
    },
    cardMedia: {
      height: 0,
      paddingTop: '56.25%'
    }
  })
