import { Note, VideoType } from 'types/models'

export const groupingNotesByVideos = (
  videos: VideoType[],
  notes: Note[]
): VideoType[] => {
  videos.forEach((video) => {
    const videoNotes: Note[] = []
    const remainingNotes = []

    for (let noteIndex = 0; noteIndex < notes.length; noteIndex++) {
      const videoNote = notes[noteIndex]

      if (videoNote.videoId === video.id) {
        videoNotes.push(videoNote)
      } else {
        remainingNotes.push(videoNote)
      }
    }
    video.notes = videoNotes

    // Optimize group
    notes = remainingNotes
  })

  return videos
}
