export default {
  LOGO: require('./Agilearn-logo.png'),
  LOGO_WHITE: require('./Icon-white.svg'),
  APPLE_STORE: require('./apple-store.png'),
  GOOGLE_PLAY: require('./google-play.png'),
  BACKGROUND_SURVEY: require('./background_survey.jpg'),
  DASHBOARD_BANNER: require('./dashboard-banner.jpg'),
  NO_THUMBNAIL: require('./no-thumbnail.jpg'),
  ROCKET: require('./rocket.png'),
  EARTH_ATMOSPHERE: require('./atmosphere.png'),
  LAUNCHERS: require('./launchers.png'),
  ROCKET_LAUNCHED: require('./rocket-launched.png'),
  ROCKET_MESSAGE: require('./rocket-message.png'),
  FUEL_CAN: require('./fuel_can.png'),
  ACCUMULATE_POINT_BACKGROUND: require('./accumulate_background.png'),
  ROCKET_BUTTON: require('./rocket-button.png'),
  ROCKET_BUTTON_MESSAGE: require('./rocket-button-message.png')
}
