import React, { useContext, Suspense } from 'react'
import RouteContext from 'contexts/RouteContext'
import { Switch, Route } from 'react-router-dom'
import Loading from 'components/Loading'
import { SuspenseWithPerf } from 'reactfire'
import Authentication from 'providers/auth/Authentication'
import { Login } from 'views'
import ErrorBlock from 'components/ErrorBlock'

interface IProps {}

const RouterView: React.FC<IProps> = (props) => {
  const routes = useContext(RouteContext)

  return (
    <>
      <Suspense fallback={<Loading />}>
        {routes && (
          <Switch>
            <Route exact path="/login" component={Login} />
            {routes.map(
              ({ childRoutes, path, component: Component }, index) => (
                <Route
                  key={path}
                  exact={!childRoutes}
                  path={path}
                  render={(props) => (
                    <SuspenseWithPerf
                      traceId={'view: ' + path}
                      fallback={<Loading />}
                    >
                      <Authentication>
                        <RouteContext.Provider value={childRoutes}>
                          <Component {...props} />
                        </RouteContext.Provider>
                      </Authentication>
                    </SuspenseWithPerf>
                  )}
                />
              )
            )}
            <Route>
              <ErrorBlock variant={'404'} />
            </Route>
          </Switch>
        )}
      </Suspense>
    </>
  )
}

export default RouterView
