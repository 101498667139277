import { Box } from '@material-ui/core'
import StudentNote from 'components/StudentNote'
import { useStudent } from 'contexts/StudentContext'
import { usePagination } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { Note, VideoType } from 'types/models'
import { groupingNotesByVideos } from 'utils/VideoUtils'
import Pagination from 'components/Pagination'

interface IProps {}

const LIMIT = 10

const StudyHistoriesTabNotes: React.FC<IProps> = () => {
  const student = useStudent()
  const pagination = usePagination<VideoType>(
    student._meta.ref
      .collection('histories')
      .orderBy('lastModifiedNoteAt', 'desc'),
    LIMIT
  )

  const [videos, setVideos] = useState<VideoType[]>([])

  const videoId = useMemo(() => pagination.items.map((v) => v.id), [pagination])

  useEffect(() => {
    if (videoId.length) {
      student._meta.ref
        .collection('notes')
        .where('videoId', 'in', videoId)
        .get()
        .then((snapshots) => {
          const notes = snapshots.docs.map(
            (snapshot) => ({ id: snapshot.id, ...snapshot.data() } as Note)
          )
          groupingNotesByVideos(pagination.items, notes)
          setVideos(pagination.items)
        })
    }
  }, [student, videoId, pagination.items])

  return (
    <>
      <Box mb={5}>
        {videos.map((video) => (
          <StudentNote key={video.id} video={video}></StudentNote>
        ))}

        <Box>
          {(pagination.hasNext || pagination.hasPrevious) && (
            <Pagination pagination={pagination}></Pagination>
          )}
        </Box>
      </Box>
    </>
  )
}

export default StudyHistoriesTabNotes
