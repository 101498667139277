import { Box, Container } from '@material-ui/core'
import PageHeader from 'components/PageHeader'
import BasicLayout from 'layouts/BasicLayout'
import Tabs from 'components/Tabs'
import Tab from 'components/Tab'
import React, { useState } from 'react'
import VideoFavorites from 'widgets/VideoFavorites'
import StudyHistoriesTabStudying from 'widgets/StudyHistoriesTabStudying'
import StudyHistoriesTabCompleted from 'widgets/StudyHistoriesTabCompleted'
import StudyHistoriesTabNotes from 'widgets/StudyHistoriesTabNotes'

interface IProps {}

const renderTabs = (tab: number) => {
  switch (tab) {
    case 0:
      return <VideoFavorites></VideoFavorites>
    case 1:
      return <StudyHistoriesTabStudying></StudyHistoriesTabStudying>
    case 2:
      return <StudyHistoriesTabCompleted></StudyHistoriesTabCompleted>
    case 3:
      return <StudyHistoriesTabNotes />
  }
}

const StudyHistories: React.FC<IProps> = () => {
  const [currentTab, setCurrentTab] = useState(0)

  return (
    <>
      <BasicLayout>
        <Container maxWidth="lg">
          <PageHeader>Lịch sử học tập</PageHeader>
          <Tabs
            onChange={(e, nextTab) => setCurrentTab(nextTab)}
            value={currentTab}
          >
            <Tab label="Yêu thích"></Tab>
            <Tab label="Đang học"></Tab>
            <Tab label="Đã hoàn thành"></Tab>
            <Tab label="Ghi chú"></Tab>
          </Tabs>

          <Box mt={2}>{renderTabs(currentTab)}</Box>
        </Container>
      </BasicLayout>
    </>
  )
}

export default StudyHistories
