import app from './app.json'

export declare type ConfigType = {
  app: {
    siteName: string
    siteSlogan: string
    siteDesc: string
    copyright: string
    appGooglePlayUrl?: string
    appAppStoreUrl?: string
  }
}

const config: ConfigType = {
  app: app
}

export default config
