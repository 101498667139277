import { Theme, createStyles } from '@material-ui/core/styles'

export default (theme: Theme) =>
  createStyles({
    searchBoxContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    searchBox: {},
    textField: {
      [theme.breakpoints.up('lg')]: {
        width: 300
      },
      [theme.breakpoints.down('lg')]: {
        width: 300
      },
      [theme.breakpoints.down('md')]: {
        width: 300
      },
      [theme.breakpoints.down('sm')]: {
        width: 200
      },
      [theme.breakpoints.down('xs')]: {
        width: 150
      }
    },
    primaryTheme: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white'
        },
        '&:hover fieldset': {
          borderColor: 'white'
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white'
        }
      },
      '& .MuiOutlinedInput-input': {
        color: 'white'
      }
    },
    searchIcon: {
      color: 'white'
    },
    suggestions: {
      position: 'absolute',
      right: 20,
      marginBottom: 50,
      [theme.breakpoints.up('lg')]: {
        width: 500
      },
      [theme.breakpoints.down('lg')]: {
        width: 600
      },
      [theme.breakpoints.down('md')]: {
        width: 600
      },
      [theme.breakpoints.down('sm')]: {
        width: 400
      },
      [theme.breakpoints.down('xs')]: {
        width: 300
      }
    },
    item: {
      marginTop: theme.spacing(2)
    },
    hitContent: {
      color: 'black',
      '& .ais-Highlight-highlighted': {
        background: theme.palette.secondary[200]
      },
      fontSize: '16px'
    },
    hitHashtags: {
      color: 'black',
      '& .ais-Highlight-highlighted': {
        background: theme.palette.secondary[200]
      }
    },
    notFoundText: {
      textDecoration: 'italic',
      color: 'black'
    }
  })
