import React from 'react'
import { VideoType } from 'types/models'
import {
  Box,
  CardMedia,
  Grid,
  Paper,
  Typography,
  Tooltip,
  Link as MLink,
  withStyles,
  Theme,
  makeStyles
} from '@material-ui/core'
import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
  CheckBox as CheckBoxIcon
} from '@material-ui/icons'
import { Link } from 'react-router-dom'
import styles from './styles'
import { numberFormat } from 'utils/SiteDecoration'

const useStyles = makeStyles(styles)

interface IProps {
  video: VideoType
}

const MyFavoriteIcon = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.error.light
  }
}))(FavoriteIcon)

const CardVideo: React.FC<IProps> = (props) => {
  const { video } = props

  const classes = useStyles()

  const boxShowTotalViews = () => {
    if (video.statistics.totalViews && video.statistics.totalViews > 1000) {
      return (
        <Box display="flex" alignItems="center">
          {numberFormat(video.statistics.totalViews)} Lượt xem
        </Box>
      )
    }
    return null
  }

  return (
    <>
      <MLink
        style={{ color: 'black' }}
        component={Link}
        to={`/watch/${video.id}`}
      >
        <Paper elevation={2}>
          <CardMedia
            style={{ height: 0, paddingTop: '56.25%' }}
            image={
              video.appearance.thumbnailUrl ||
              'https://urbandojo.com/wp-content/uploads/2017/04/default-image-720x530.jpg'
            }
            title={video.title}
          />
          <Box pb={4} p={2}>
            <Tooltip
              title={
                <Typography align={'center'} variant="body1">
                  {video.title}
                </Typography>
              }
            >
              <Typography noWrap variant="h6">
                {video.title}
              </Typography>
            </Tooltip>

            <Box mt={8} display="flex">
              <Grid container>
                <Grid item xs={6}>
                  {boxShowTotalViews()}
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="flex-end">
                    {video.watched && (
                      <CheckBoxIcon className={classes.watchedIcon} />
                    )}
                    {video.favorite ? (
                      <MyFavoriteIcon color="error" />
                    ) : (
                      <FavoriteBorderIcon />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </MLink>
    </>
  )
}

export default CardVideo
