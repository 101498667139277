import { createStyles, Theme } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    rocket: {
      position: 'absolute',
      top: -80,
      left: '50%',
      transform: 'translate(-50%, 0)',
      cursor: 'pointer'
    },
    rocketMessaeg: {
      color: 'white',
      top: '50%',
      left: '50%',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      textAlign: 'center',
      fontSize: theme.typography.body1.fontSize,
      fontWeight: 600
    },
    root: {
      position: 'fixed',
      right: '1%',
      bottom: '1%'
    }
  })
