import { Field, Topic } from 'types/models'

export function validateFields(fields: {
  [id: string]: { value: Field; state: boolean }
}): string {
  let error = ''
  let chosenFields = 0

  for (const field in fields) {
    if (fields[field].state) {
      chosenFields += 1
    }
  }
  if (chosenFields < 2) {
    error = 'Chọn ít nhất 2 lĩnh vực'
  }
  return error
}

export function validateTopics(topics: {
  [key: string]: { value: Topic; state: boolean }
}): string {
  let error = ''

  const chosenTopics = Object.keys(topics).filter((id) => topics[id].state)
  if (chosenTopics.length < 5) {
    error = 'Chọn ít nhất 5 chủ đề'
  }
  return error
}

export function getChosenFields(
  options: {
    [id: string]: boolean
  },
  baseFields: Array<Field>
): Array<Field> {
  let chosenFields: Array<Field> = []

  for (const option in options) {
    if (options[option]) {
      for (const field of baseFields) {
        if (field.id === option) {
          chosenFields.push(field)
        }
      }
    }
  }

  return chosenFields
}

export function getChosenTopics(
  options: {
    [id: string]: boolean
  },
  baseTopics: Array<Topic>,
  chosenTopics: Array<Topic>
): Array<Topic> {
  let chosenTopicsClone: Array<Topic> = chosenTopics

  for (const option in options) {
    if (options[option]) {
      for (const topic of baseTopics) {
        if (topic.id === option) {
          chosenTopicsClone.push(topic)
        }
      }
    }
  }

  return chosenTopicsClone
}
