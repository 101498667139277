import { Theme, createStyles } from '@material-ui/core/styles'

export default (theme: Theme) =>
  createStyles({
    root: {
      background: 'white'
    },

    footer: {
      margin: '0 auto',
      padding: theme.spacing(3)
    }
  })
