import { createStyles, Theme } from "@material-ui/core/styles";

export default (theme: Theme) => createStyles({
  headerMenu: {
    '& a': {
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    '& menu-list': {
      display: 'inline-block'
    },
  },
})