import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  Typography
} from '@material-ui/core'
import {
  ExpandMore as ExpandMoreIcon,
  PlayCircleOutline as PlayCircleOutlineIcon
} from '@material-ui/icons'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Note, VideoType } from 'types/models'
import { parseVideoTime } from 'utils'

interface IProps {
  video: VideoType
}

interface INoteItemProps {
  note: Note
  video: VideoType
}

const NoteItem: React.FC<INoteItemProps> = ({ note, video }) => {
  const history = useHistory()

  const handleOnclick = useCallback(() => {
    history.push(`/watch/${video.id}?time=${note.tookAt}`)
  }, [history, video.id, note.tookAt])

  return (
    <ListItem
      onClick={handleOnclick}
      style={{ display: 'flex', justifyContent: 'space-between' }}
      button
    >
      <Typography
        style={{ display: 'flex', alignItems: 'center' }}
        variant="body1"
      >
        <PlayCircleOutlineIcon style={{ marginRight: 5 }} />

        {note.content}
      </Typography>

      <Box mr={2}>
        <Typography variant="caption">{parseVideoTime(note.tookAt)}</Typography>
      </Box>
    </ListItem>
  )
}

const StudentNote: React.FC<IProps> = ({ video }) => {
  const title = video.title

  const notes = useMemo(() => (video.notes ? video.notes : []), [video.notes])

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box style={{ width: '100%' }}>
            <Typography variant="h5">{title}</Typography>
            <Typography variant="caption">{notes.length} ghi chú</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%">
            <List component="nav">
              {notes &&
                notes.map((note) => (
                  <NoteItem key={note.id} video={video} note={note}></NoteItem>
                ))}
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default StudentNote
