import React, { useMemo, useState } from 'react'
import IMAGES from 'assets/images'
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core'
import styles from './styles'
import { Link } from 'react-router-dom'
import { useStudent } from 'contexts/StudentContext'
import { useFirestore, useUser } from 'reactfire'
import { useDocumentData } from 'hooks'
import { Rocket } from 'types/models'
import { Close as CloseIcon } from '@material-ui/icons'

const useStyles = makeStyles(styles)

interface IProps {}

const RocketButtonBody: React.FC<IProps> = () => {
  const classes = useStyles()

  const user = useUser<firebase.User>()

  const db = useFirestore()
  const myRocket = useDocumentData<Rocket>(
    db.collection('rockets').doc(user.uid),
    { idField: 'id' }
  )

  const [show, setShow] = useState(true)

  const myRocketHeight = useMemo(() => (myRocket ? myRocket.height : 0), [
    myRocket
  ])

  return (
    <>
      {show && (
        <div className={classes.root}>
          <Box>
            <IconButton
              onClick={() => setShow(false)}
              style={{ position: 'absolute', top: -120, right: -10, zIndex: 2 }}
            >
              <CloseIcon />
            </IconButton>
            <Link style={{ zIndex: 1 }} to={'/my-rocket'}>
              <Box className={classes.rocket}>
                <img src={IMAGES.ROCKET_BUTTON} alt="" />
              </Box>
              <Box>
                <img src={IMAGES.ROCKET_BUTTON_MESSAGE} alt="" />
                <Typography className={classes.rocketMessaeg}>
                  {myRocketHeight.toFixed(0)} m
                </Typography>
              </Box>
            </Link>
          </Box>
        </div>
      )}
    </>
  )
}

const RocketButton: React.FC<IProps> = () => {
  const user = useUser()

  if (!user) {
    return null
  }

  return <RocketButtonBody />
}

export default RocketButton
