import React, { FC } from 'react'
import {
  Box,
  Toolbar,
  AppBarProps,
  Link as MLink,
  makeStyles
} from '@material-ui/core'
import configs from 'configs'
import images from 'assets/images'
import { Link } from 'react-router-dom'
import styles from './styles'
import UserMenu from 'components/UserMenu'
import AppBar from 'components/Appbar'
import SearchBar from 'widgets/SearchBar'
import TopMenu from 'components/TopMenu'

const useStyles = makeStyles(styles)

interface IProps {
  bgColor?: AppBarProps['color']
  searchRefinement?: string
  theme: 'light' | 'dark'
}

const Header: FC<IProps> = (props) => {
  const { bgColor = 'primary' } = props

  const classes = useStyles()

  return (
    <Box display="flex">
      <AppBar position="static" color={bgColor}>
        <Toolbar>
          <Box
            width="100%"
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <MLink className={classes.logo} component={Link} to="/">
                <img src={images.LOGO} alt={configs.app.siteName} />
              </MLink>
              <TopMenu
                variant={bgColor === 'primary' ? 'primary' : 'default'}
              />
            </Box>
            <Box display={'flex'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <SearchBar
                  theme={props.theme}
                  searchRefinement={props.searchRefinement}
                />
              </Box>
              <UserMenu />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header
