import React from 'react'
import './App.css'
import routing from 'routing'
import RouteContext from 'contexts/RouteContext'
import RouterView from 'components/RouterView'
import { Router } from 'react-router-dom'
import { history } from 'providers/router/BrowserHistory'
import { PageViewLogger, preloadSDKs } from 'utils'
import { useFirebaseApp } from 'reactfire'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import LightTheme from 'themes/LightTheme'
import ErrorBoundary from 'components/ErrorBoundary'
import ErrorBlock from 'components/ErrorBlock'
import { getPageTitle } from 'utils/SiteDecoration'
import RocketButton from 'widgets/RocketButton'

function App() {
  const firebaseApp = useFirebaseApp()
  preloadSDKs(firebaseApp)

  document.title = getPageTitle()

  return (
    <ThemeProvider theme={LightTheme}>
      <CssBaseline />
      <ErrorBoundary fallback={ErrorBlock}>
        <Router history={history}>
          <RouteContext.Provider value={routing}>
            <RouterView />
            <RocketButton />
          </RouteContext.Provider>
        </Router>
        <PageViewLogger />
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
