import React from 'react'
import { Typography } from '@material-ui/core'
import styles from './styles'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(styles)

interface IProps {}

const PageHeader: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.container}>
        <Typography variant="h3" component="h1" gutterBottom>
          {props.children}
        </Typography>
      </div>
    </>
  )
}

export default PageHeader
